import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreLoader from "components/preLoader/PreLoader";

const Home = lazy(() => import("../pages/Home"));
const TOS = lazy(() => import("../pages/Tos"));

const Config = () => {
  return (
    <Router>
      <Suspense fallback={<PreLoader />}>
        <Routes>
          <Route path="/" element={<Home />} />
           <Route path="/terms" element={<TOS />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Config;
